body {
  font-family: "Segoe UI", Tahoma, sans-serif;
  font-size: 14px;

  padding: 0;
  margin: 0;

  background-color: var(--main-bg-color-light);
  color: var(--main-color-light);
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: var(--main-bg-color-dark);
    color: var(--main-color-dark);
  }
}