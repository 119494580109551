.imagesContainer {
  position: relative;
  width: 500px;
  height: 500px;
}

.popup {
  position: absolute;
  top: 75px;
}

.svgImage {
  position: absolute;
  left: 0;
  top: 0;
  width: 500px;
}

.arrow {
  position: absolute;
  right: 120px;
  top: 0;
  width: 70px;
  z-index: 1;
  transform: rotate(320deg);
  pointer-events: none;
}