.container {
  background: var(--main-bg-color-light);
  z-index: 1;
}

.wholePageButton {
  background: #1A32C8;
}

.visibleAreaButton {
  background: #FDD279;
}

.customAreaButton {
  background: #7fdb92
}

.wholePageButton *, .visibleAreaButton *, .customAreaButton * {
  color: white;
}

@media (prefers-color-scheme: dark) {
  .container {
    background: var(--main-bg-color-dark);
  }
}