.button {
  border-radius: 4px;
  padding: 0;
  margin: 0;
  height: unset!important;
  transition: .3s;
  box-shadow: 0 0 0 0 black;
  transform: scale(1);

  &:hover {
    box-shadow: 0 0 20px -10px black;
    transform: scale(1.01);
  }
}

.paddingBox {
  width: 100px;
}

.disabled {
  filter: grayscale(100%);
  background: #8a8a8a !important;

  &:hover {
    box-shadow: unset;
    transform: scale(1);
  }
}