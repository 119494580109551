.st0{clip-path:url(#SVGID_B2_);}
.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#0F24A6;}
.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#F7A600;}
.st4{fill-rule:evenodd;clip-rule:evenodd;fill:#F5F7FF;}
.st5{fill-rule:evenodd;clip-rule:evenodd;fill:#1A32C8;}
.st6{fill:#D6DBFF;}
.st7{fill:#1A32C8;}
.st8{fill:#FFFFFF;}
.st9{clip-path:url(#SVGID_B4_);}
.st10{fill:#324BE4;}
.st11{clip-path:url(#SVGID_B6_);}
.st12{clip-path:url(#SVGID_B8_);}
.st13{clip-path:url(#SVGID_B10_);}
.st14{clip-path:url(#SVGID_B12_);}
.st15{clip-path:url(#SVGID_B14_);}
.st16{clip-path:url(#SVGID_B16_);}