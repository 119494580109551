.st0{fill:#FEED8F;}
.st1{fill:#FFF5BF;}
.st2{fill:#DCAE54;}
.st3{fill:#5751E9;}
.st4{fill:#282877;}
.st5{fill:#FFFFFF;}
.st6{fill:#B5ADFF;}
.st7{fill:#301668;}
.st8{fill:#6947C5;}
.st9{fill:#FDD279;}
.st10{fill:#333333;}
.st11{fill:#272425;}