.footer {
  min-height: 255px;
  background: var(--bg);
  position: relative;
  & > * {
    min-height: 255px;
    & > * {
      min-height: 255px;
    }
  }
}