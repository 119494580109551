.headerContainer {
  background: var(--grayscale-gs-white);
  box-shadow: 0 8px 16px 0 rgba(17, 17, 17, 0.10);
  position: fixed;
  z-index: 1;

  &_desktop {
    border-radius: 1000px;
    width: 80%;
    left: 10%;
    top: 16px;
  }

  &_mobile {
    width: 100%;
    left: 0;
    top: 0;
  }
}

@media (prefers-color-scheme: dark) {
  .headerContainer {
    background: var(--grayscale-gs-primary);
  }
}