.menuItem {
  color: var(--grayscale-gs-secondary-1);
  font-style: normal;
  line-height: 100%;
  transition: .3s;
  position: relative;

  &:after {
    position: absolute;
    bottom: 0;
    left: 50%;
    content: '';
    width: 0;
    opacity: 0;
    height: 2px;
    transition: .3s;
  }

  &:hover {
    color: var(--grayscale-gs-secondary-2);
    &:after {
      background: var(--grayscale-gs-secondary-2);
      width: 100%;
      left: 0;
      opacity: 1;
    }
  }

  &:active {
    color: var(--grayscale-gs-tetriary-1);
  }

  &_selected {
    color: var(--primary);

    &:hover {
      color: var(--primary-hover);
      &:after {
        background: var(--primary-hover);
      }
    }

    &:active {
      color: var(--primary-pressed);
    }
  }
}