.st0{clip-path:url(#SVGID_W2_);}
.st1{fill:#FFE7B6;}
.st2{clip-path:url(#SVGID_W4_);}
.st3{clip-path:url(#SVGID_W6_);}
.st4{fill:#EDC574;}
.st5{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
.st6{fill-rule:evenodd;clip-rule:evenodd;fill:#333333;}
.st7{fill-rule:evenodd;clip-rule:evenodd;fill:#F7A600;}
.st8{clip-path:url(#SVGID_W8_);}
.st9{clip-path:url(#SVGID_W10_);}
.st10{clip-path:url(#SVGID_W12_);}
.st11{fill:#FFF0DA;}