@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter-VariableFont_slnt,wght.ttf") format('truetype');
}
* {
    font-family: "Inter", sans-serif;
}

html {
    scroll-behavior: smooth;
}