.button {
  border-radius: 1000px;
  border: unset;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  cursor: pointer;
  display: block;
  color: var(--bg);
  & * {
    fill: var(--bg);
  }

  &_primary {
    padding: 20px 32px;
    background: var(--primary);
    color: var(--grayscale-gs-white);
    & * {
      fill: var(--grayscale-gs-white);
    }

    &:hover {
      background: var(--primary-hover);
    }

    &:active {
      background: var(--primary-pressed);
    }
  }

  &:disabled {
    filter: grayscale(1);
    cursor: not-allowed;
  }

  &_link {
    background: unset;
    padding: 12px;

    &:hover {
      background: rgba(58, 58, 58, 0.2);
      & * {
        fill: var(--primary);
      }
    }

    &:active {
      transform: scale(0.98);
      background: rgba(58, 58, 58, 0.3);
    }
  }

  &_dark {
    color: var(--bg) !important;
    & * {
      fill: var(--bg) !important;
    }
  }

  &_light {
    color: var(--grayscale-gs-white)!important;
    & * {
      fill: var(--grayscale-gs-white) !important;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .button {
    color: var(--grayscale-gs-white);
    & * {
      fill: var(--grayscale-gs-white);
    }
  }
}