.container {
  &_colored {
    color: var(--primary);
  }

  &_light {
    color: var(--grayscale-gs-white);
  }

  &_dark {
    color: var(--bg);
  }
}

.icon {
  &_colored {
    * {
      fill: var(--primary);
    }
  }

  &_light {
    * {
      fill: var(--grayscale-gs-white);
    }
  }

  &_dark {
    * {
      fill: var(--bg);
    }
  }
}