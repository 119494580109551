:root {
    --main-bg-color-dark: #202124;
    --main-color-dark: #e8eaed;
    --main-bg-color-light: #f5f5f5;
    --main-color-light: #202124;

    --accent-color: #2263a4;
    --accent-color-1: #f1f0ab;

    --primary: #044BD9;
    --primary-hover: #366FE1;
    --primary-pressed: #6893E8;

    --secondary: #DFE4F2;
    --bg: #111;
    --grayscale-gs-primary: #232329;
    --primary-2: #43434E;
    --grayscale-gs-secondary-1: #68687B;
    --grayscale-gs-secondary-2: #A1A1AF;
    --grayscale-gs-tetriary-1: #B0B0BC;
    --grayscale-gs-tetriary-2: #D0D0D7;
    --grayscale-gs-quarterly-1: #DFDFE4;
    --grayscale-gs-background-darker: #F3F4F7;
    --grayscale-gs-background: #FAFAFA;
    --grayscale-gs-quarterly-2: #FDFDFD;
    --grayscale-gs-white: #FFF;
}

