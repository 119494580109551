.arrow {
  transition: .3s;

  &_up {
    transform: rotate(180deg);
  }
}

.collapseButton {
  cursor: pointer;
}

.collapseContainer {
  overflow: hidden;
  transition: .3s;
  border-radius: 32px;
  border: 0.5px solid var(--grayscale-gs-quarterly-1);
  background: var(--grayscale-gs-white);
}

@media (prefers-color-scheme: dark) {
  .collapseContainer {
    background: var(--bg);
    border: 0.5px solid var(--primary-2);
  }
}