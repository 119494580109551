.desktopButton {
  background: #5EBF72;
}

.webcamButton {
  background: #FDD279;
}

.bothButton {
  background: #1A32C8;
}

.desktopButton *, .webcamButton *, .bothButton * {
  color: white;
}

.container {
  background: var(--main-bg-color-light);
  z-index: 1;
}



@media (prefers-color-scheme: dark) {
  .container {
    background: var(--main-bg-color-dark);
  }
}