.section {
  min-height: 700px;
  background-color: color-mix(in srgb, var(--grayscale-gs-white) 95%, white 0%);
  backdrop-filter: blur(50px);
  border-top: 1px solid var(--grayscale-gs-tetriary-2);
  border-bottom: 1px solid var(--grayscale-gs-tetriary-2);

  & > * {
    min-height: 700px;
    & > * {
      min-height: 700px;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .section {
    background-color: color-mix(in srgb, var(--grayscale-gs-primary) 95%, white 0%);
    border-top: 1px solid var(--grayscale-gs-secondary-1);
    border-bottom: 1px solid var(--grayscale-gs-secondary-1);
  }
}