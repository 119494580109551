.imgContainer {
  width: 360px;
  position: absolute;
  z-index: -1;
  top: 30%;

  svg {
    width: 100%;
  }
}

.welcomeComponent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.gradient {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  background: linear-gradient(0deg, var(--grayscale-gs-white) 0%, var(--grayscale-gs-white) 40%, rgba(0,0,0,0) 60%,rgba(0,0,0,0) 100%);
}

@media (prefers-color-scheme: dark) {
  .gradient {
    background: linear-gradient(0deg, var(--grayscale-gs-primary) 0%, var(--grayscale-gs-primary) 40%, rgba(0,0,0,0) 60%,rgba(0,0,0,0) 100%);
  }
}