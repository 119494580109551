.browserImage {
  width: 550px;
  img {
    width: 100%;
  }
}

.imagesContainer {
  position: relative;
  height: 480px;
}

.popup {
  position: absolute;
  right: 0;
}

.arrowImage {
  pointer-events: none;
  position: absolute;
  right: 140px;
  top: 250px;
  width: 210px;
  z-index: 1;
}