.st0{fill:#5EBF72;filter:url(#Adobe_OpacityMaskFilter1);}
.st1{mask:url(#mask0_1_);}
.st2{fill:#EDC573;}
.st3{fill:#FFFFFF;}
.st4{fill:#EEEEEE;}
.st5{fill:#FDD279;}
.st6{fill:#54B468;}
.st7{clip-path:url(#SVGID_DC9_);}
.st8{fill:#69D57F;}
.st9{clip-path:url(#SVGID_DC4_);}
.st10{clip-path:url(#SVGID_DC6_);}
.st11{clip-path:url(#SVGID_DC8_);}
.st12{clip-path:url(#SVGID_DC10_);}
.st13{clip-path:url(#SVGID_DC12_);}
.st14{clip-path:url(#SVGID_DC14_);}
.st15{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
.st16{fill-rule:evenodd;clip-rule:evenodd;fill:#FDD279;}
.st17{fill-rule:evenodd;clip-rule:evenodd;fill:#EDC573;}
.st18{fill-rule:evenodd;clip-rule:evenodd;fill:#2F2F2F;}
.st19{fill-rule:evenodd;clip-rule:evenodd;fill:#FFF0DA;}
.st20{fill:#2F2F2F;}