.menu {
  z-index: 10;
  position: fixed;
  top: 72px;
  right: -100%;
  height: calc(100% - 72px);
  width: 100%;
  transition: .3s;
  padding: 20px;
  backdrop-filter: blur(40px);
  background-color: color-mix(in srgb, var(--grayscale-gs-background) 50%, white 0%);

  &_visible {
    right: 0;
  }

  &_hidden {
    right: -100%;
  }

  &_hiddenDone {
    right: -100%;
  }
}

@media (prefers-color-scheme: dark) {
  .menu {
    background-color: color-mix(in srgb, var(--bg) 80%, white 0%);
  }
}