.icon svg {
  width: 22px;
  height: 22px;
}

.mainColor {
  color: var(--accent-color);
  fill: var(--accent-color);
}

.dark * {
  fill: var(--main-color-dark);
}

.light * {
  fill: var(--main-color-light);
}

.largeIcon svg {
  width: 100px;
  height: 100px;
}